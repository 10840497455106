<template>
  <!-- :height="isMaxUsers ? 110 : 80"  -->
  <v-card
    color="#385F73"
    dark
    :height="creationEnabled ? 80 : 110"
    class="mb-2"
  >
    <div class="d-flex align-center justify-space-between pt-2">
      <div class="px-3">
        <p class="ma-0 text-subtitle-2">
          Total usuarios
          <v-progress-circular
            indeterminate
            size="14"
            width="2"
            v-if="loader"
          ></v-progress-circular>
          <span v-else class="font-weight-bold">{{
            data.business.total_users
          }}</span>
          de
          <v-progress-circular
            indeterminate
            size="14"
            width="2"
            v-if="loader"
          ></v-progress-circular>
          <span v-else class="font-weight-bold">{{
            data.plan.total_users
          }}</span>
        </p>
        <div class="text-h4 ma-0 mb-0 pl-1 d-flex">
          <v-progress-circular
            indeterminate
            size="33"
            v-if="loader"
          ></v-progress-circular>
          <template v-else>
            <span>{{ data.business.total_users }}</span>
            <div>
              <p class="caption ml-4 ma-0">
                {{ data.business.admin }} de
                <span>{{ data.plan.admin }}</span> Admin
              </p>
              <p class="caption ml-4 ma-0">
                {{ data.business.employee }} de
                <span>{{ data.plan.employee }} </span> Empleados
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="px-3">
        <v-icon x-large>account_circle</v-icon>
      </div>
    </div>
    <div v-if="isMaxUsers" class="text-center mt-1">
      <v-btn small>¿Necesitas más usuarios?</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    isMaxUsers() {
      if (this.data) {
        if (this.data.business && this.data.plan) {
          if (this.data.business.total_users >= this.data.plan.total_users)
            return true;
          else return false;
        }
      }
      return false;
    }
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    loader: {
      type: Boolean,
      default: true
    },
    creationEnabled: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style></style>
