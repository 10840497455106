<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Usuarios
        </h3>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="9"
        xl="9"
        order-lg="1"
        order-xl="1"
        class="pb-0"
      >
        <app-total-users
          :data="usersMetadataPlan"
          :loader="loaderTotalUsers"
        ></app-total-users>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="9"
        xl="9"
        order-lg="1"
        order-xl="1"
        class="pt-0 pb-0"
      >
        <v-card width="100%">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0">
                <v-btn
                  color="primary"
                  :loading="loaderTotalUsers"
                  :disabled="creationEnabled"
                  :creationEnabled="creationEnabled"
                  block
                  @click="$router.push({ name: 'userRegister' })"
                >
                  Agregar usuario</v-btn
                >
                <div class="caption my-1">
                  Agrega a tus colaboradores para que tengan una cuenta unica
                  con la cual pueda acceder a la app
                </div>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  label="Buscar"
                  outlined
                  dense
                  v-model="searchUser"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  class="card_background"
                  :headers="headers"
                  :search="searchUser"
                  :items="userlist"
                  :items-per-page="10"
                  @update:page="$vuetify.goTo(0)"
                  :loading="loadingTable"
                >
                  <template v-slot:item.photo="{ item }">
                    <v-avatar v-if="item.photo" size="34">
                      <img :src="item.photo" alt="image" />
                    </v-avatar>
                    <v-avatar v-else color="primary" size="34">
                      <span class="white--text text-h6">{{
                        item.first_name[0].toUpperCase()
                      }}</span>
                    </v-avatar>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-chip
                      :color="item.status ? 'success' : 'grey white--text'"
                      small
                    >
                      {{ item.status ? "Activo" : "Inactivo" }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { database } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";
import TotalUsers from "@/components/users/TotalUsers";
export default {
  data() {
    return {
      loadingTable: true,
      searchUser: null,
      headers: [
        {
          text: "Imagen",
          sortable: false,
          value: "photo"
        },
        { text: "Nombre(s)", value: "first_name" },
        { text: "Apellidos", value: "last_name" },
        { text: "Role", value: "role" },
        { text: "Correo", value: "email" },
        { text: "status", value: "status" }
      ],
      userlist: [],
      userStatusRef: null,
      loaderTotalUsers: true,
      usersMetadataPlan: {},
      creationEnabled: true
    };
  },
  mounted() {
    this.getUsersData();
    this.getBusinessMetadata();
  },
  methods: {
    ...mapActions("business", ["getBusinessPlan", "getBusinessState"]),
    ...mapActions("user", ["getAllUsers"]),
    async getUsersData() {
      try {
        const users = await this.getAllUsers();
        this.userlist = users;
        this.loadingTable = false;
        if (this.userlist.length > 0) {
          this.userStatusRef = database(`user_status`);
          // :: Get online or offline status user
          this.userStatusRef.on("value", async snapshot => {
            if (snapshot.exists()) {
              const userListChanged = [...this.userlist];
              snapshot.forEach(childSnapshot => {
                let index = this.userlist.findIndex(
                  user => user.uid === childSnapshot.key
                );
                if (index != -1) {
                  let status =
                    childSnapshot.val().state === "online" ? true : false;
                  userListChanged[index].status = status;
                }
              });
              this.userlist = userListChanged;
            }
          });
        }
      } catch (error) {
        errorMessage({
          message: "Error al obtener los datos de los usuarios"
        });
      }
    },
    async getBusinessMetadata() {
      this.loaderTotalUsers = true;
      const businessPlan = await this.getBusinessPlan();
      const businessData = await this.getBusinessState();
      this.usersMetadataPlan.plan = businessPlan.users;
      this.usersMetadataPlan.business = businessData.users;
      this.creationEnabled =
        businessData.users.total_users >= businessPlan.users.total_users;
      this.loaderTotalUsers = false;
    },
    goToDetail(userID) {
      this.$router.push({ name: "userDetail", params: { user: userID } });
    }
  },
  beforeDestroy() {
    // :: Detach databaseRef listener
    if (this.userlist.length > 0) {
      this.userStatusRef.off("value");
      this.userStatusRef = null;
    }
  },
  components: {
    AppTotalUsers: TotalUsers
  },
  computed: {
    ...mapState("user", ["data"])
  }
};
</script>
